/** @jsxRuntime classic */
/** @jsx jsx */
import { Container, Link, Text } from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import SiteSearchSection from '@bottlebooks/gatsby-theme-event/src/components/LandingPage/SiteSearchSection';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { LinkProvider } from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ExploreWinesByProducer from '../../components/ExploreWines/ExploreWinesByProducer';
import ExploreWinesSwitcher from '../../components/ExploreWines/ExploreWinesSwitcher';
import formatProducers from '../../formatProducers';
import groupProducers from '../../groupProducers';

export default function ExploreByProducerPage({ pageContext, data }) {
  const { event, producers } = afterQuery(data);
  return (
    <LinkProvider value={pageContext.paths}>
      <Layout>
        <EventBanner event={event} withOverlay={false} />
        <SiteSearchSection locale={pageContext.locale} />
        <Container>
          <ExploreWinesSwitcher>
            <Link to="/products">
              <Text>
                Or search by vegan, organic, on-trade and 10 other filters….
              </Text>
            </Link>
          </ExploreWinesSwitcher>
          <ExploreWinesByProducer producers={producers} sx={{ marginY: 3 }} />
        </Container>
      </Layout>
    </LinkProvider>
  );
}

function afterQuery(data) {
  const { bottlebooks } = data;
  const { theme, importers, exporters } = bottlebooks;

  /**
   * Here are the objectives of this logic:
   * 1) DOs should be sorted by name excluding DO/VLDP
   * 2) DOs should come before VLDP
   * 3) Producers should be alpha sorted within each DO
   * 4) Producers with wines that don't have any DO should not be displayed
   */
  const producersFromImporters = formatProducers(importers);
  const producersFromExporters = formatProducers(exporters);
  const enumeratedProducers = groupProducers(
    producersFromImporters.concat(producersFromExporters)
  );

  return {
    event: theme,
    producers: enumeratedProducers,
  };
}

export const query = graphql`
  query ExploreByProducerPage($locale: Bottlebooks_ContentLocale!) {
    bottlebooks {
      theme: event(eventId: "6048b4376a7c621a467c85d5", locale: $locale) {
        name
        ...bb_EventBanner
      }
      importers: event(eventId: "6033a2d31d00c321389bf5f7", locale: $locale) {
        ...ExploreByProducerPage_Fragment
      }
      exporters: event(eventId: "603f54d910828b19f691ff46", locale: $locale) {
        ...ExploreByProducerPage_Fragment
      }
    }
  }
  fragment ExploreByProducerPage_Fragment on Bottlebooks_Event {
    products: registeredProducts {
      byRegion: group(
        field: denomination
        sort: { fields: totalCount, order: DESC }
      ) {
        fieldValue
        totalCount
        byProducer: group(
          field: producerName
          first: 1
          sort: { fields: fieldValue }
        ) {
          fieldValue
          totalCount
          nodes {
            product {
              producer {
                producerId
                ...ExploreWinesByProducer
                name
              }
            }
          }
        }
      }
    }
  }
`;
